<template>
  <v-container>
    <v-row class="top">
      <v-col cols="5">
        <div class="spacer">&nbsp;</div>
      </v-col>
      <v-col cols="2" class="mt-2 text-center">
        <router-link to="/">
          <v-img
            alt="Splinterlib Crystal Ball"
            contain
            src="../assets/splinterlib.svg"
            height="4em"
            height-md="6em"
          />
        </router-link>
      </v-col>
      <v-col cols="5">
        <div class="spacer">&nbsp;</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" offset-md="3" class="text-center">
        <h1>Card Universe</h1>
      </v-col>
      <v-col cols="4" offset-md="0" offset="8" md="3">
        <v-switch
          v-model="useQa"
          label="QA Server"
          :loading="useQaLoading"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-select
          :items="filterMenu.edition.options"
          :label="filterMenu.edition.name"
          v-model="editionFilter"
          multiple
          clearable
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="filterMenu.type.options"
          :label="filterMenu.type.name"
          v-model="typeFilter"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="filterMenu.rarity.options"
          :label="filterMenu.rarity.name"
          v-model="rarityFilter"
          multiple
          clearable
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="filterMenu.splinter.options"
          :label="filterMenu.splinter.name"
          v-model="splinterFilter"
          multiple
          clearable
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="filterMenu.ability.options"
          :label="filterMenu.ability.name"
          v-model="abilityFilter"
          multiple
          clearable
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="filterMenu.format.options"
          :label="filterMenu.format.name"
          v-model="formatFilter"
          multiple
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="cardsShown">
      <v-col cols="12" class="mt-2 text-center">
        <CardCollection :cards="this.cards" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="8"
        offset-sm="2"
        md="6"
        offset-md="3"
        class="text-center"
      >
        <v-btn
          v-if="!cardsShown"
          @click="showCards"
          x-large
          block
          color="primary"
          class="mb-2"
        >
          Show Cards ({{ count }})
        </v-btn>
        <v-btn
          v-if="cardsShown"
          @click="hideCards"
          medium
          block
          color="secondary"
          class="mb-2"
        >
          Hide Cards
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import CardCollection from "@/components/CardCollection";
import { ACTION, FILTER_MENU } from "@/store/modules/cardUniverse";

export default {
  name: "CardUniverse",

  components: {
    CardCollection,
  },

  computed: {
    ...mapState({
      cards: (state) => state.cardUniverse.cards,
      count: (state) => state.cardUniverse.cards.length,
    }),
    abilityFilter: {
      get: function () {
        return this.$store.state.cardUniverse.filter.ability;
      },
      set: async function (values) {
        this.$store.dispatch(ACTION.FILTER, { filter: "ability", values });
      },
    },
    editionFilter: {
      get: function () {
        return this.$store.state.cardUniverse.filter.edition;
      },
      set: async function (values) {
        this.$store.dispatch(ACTION.FILTER, { filter: "edition", values });
      },
    },
    formatFilter: {
      get: function () {
        return this.$store.state.cardUniverse.filter.format;
      },
      set: async function (values) {
        this.$store.dispatch(ACTION.FILTER, { filter: "format", values });
      },
    },
    rarityFilter: {
      get: function () {
        return this.$store.state.cardUniverse.filter.rarity;
      },
      set: async function (values) {
        this.$store.dispatch(ACTION.FILTER, { filter: "rarity", values });
      },
    },
    splinterFilter: {
      get: function () {
        return this.$store.state.cardUniverse.filter.splinter;
      },
      set: async function (values) {
        this.$store.dispatch(ACTION.FILTER, { filter: "splinter", values });
      },
    },
    typeFilter: {
      get: function () {
        return this.$store.state.cardUniverse.filter.type;
      },
      set: async function (values) {
        this.$store.dispatch(ACTION.FILTER, { filter: "type", values });
      },
    },
    useQa: {
      get: function () {
        return this.$store.state.cardUniverse.useQa;
      },
      set: async function (value) {
        this.useQaLoading = true;
        await this.$store.dispatch(ACTION.TOGGLE_QA, value);
        this.useQaLoading = false;
      },
    },
  },

  data: () => ({
    cardsShown: false,
    useQaLoading: false,
    filterMenu: FILTER_MENU,
  }),

  methods: {
    hideCards() {
      this.cardsShown = false;
    },
    showCards() {
      this.cardsShown = true;
    },
  },

  mounted() {
    document.title = "Card Universe - Splinterlib";
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.top {
  .spacer {
    border-bottom: 1px solid $green;
    width: 100%;
    height: 50%;
  }
}
</style>
