import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        accent: "#553F64", // Purple
        caution: "#E6AA33", // Yellow
        error: "#820004", // Red
        info: "#56B4DC", // Bright blue
        primary: "#057299", // Blueish Teal
        secondary: "#2E2F2A", // Dark Gray/Green
        success: "#00827F", // Greenish Teal
        warning: "#F08A4B", // Orange
      },
    },
  },
});
