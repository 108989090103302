<template>
  <v-container>
    <v-row class="top">
      <v-col cols="5">
        <div class="spacer">&nbsp;</div>
      </v-col>
      <v-col cols="2" class="mt-2 text-center">
        <router-link to="/">
          <v-img
            alt="Splinterlib Crystal Ball"
            contain
            src="../assets/splinterlib.svg"
            height="4em"
            height-md="6em"
          />
        </router-link>
      </v-col>
      <v-col cols="5">
        <div class="spacer">&nbsp;</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" class="mt-6">
        <p>
          Helper library for Splinterlands APIs by
          <a href="https://nullfa.me">Fame</a> (aka
          <a href="https://twitter.com/nullfame">@nullfame</a>)
        </p>

        <h1>What is Splinterlib?</h1>

        <p>
          <a href="https://github.com/nullfame/knowdev-splinterlib"
            >Splinterlib</a
          >
          aims to provide:
        </p>

        <ul>
          <li>Low-level API calls using axios</li>
          <li>Utilities to make digesting API responses more intuitive</li>
        </ul>

        <p>
          You can find the
          <a href="https://github.com/nullfame/knowdev-splinterlib"
            >full source code on GitHub</a
          >.
        </p>

        <p>
          The ultimate goal would be to support all of the APIs. That is not
          part of my plans at this time. My current plan is only to implement
          functions I need for other projects I am pursuing.
        </p>

        <p>
          This site is meant to be an interactive demonstration of the
          capabilities of Splinterlib. It is not meant to compete with the much
          more awesome and complete
          <a href="https://splintercards.com/">Splintercards.com</a> by
          <a href="https://peakd.com/@tehox">@tehox</a>. A better way to think
          of Splinterlib is that it would be used by sites like Splintercards,
          <a href="https://splintertools.io/">Splintertools.io</a> by
          <a href="https://peakd.com/@splintertools">@splintertools</a>, or some
          future developer trying to innovate.
        </p>

        <h1>What is Splinterlands?</h1>

        <p>
          <a href="https://splinterlands.com?ref=nullfame">Splinterlands</a> is
          a digital NFT collectable card game that runs on the Hive blockchain.
          Its a lot like Magic the Gathering. What makes an NFT/blockchain game
          different is "true player ownership" of assets. In other words you can
          buy, sell, trade, or rent any of your assets with other players.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="8"
        offset-sm="2"
        md="6"
        offset-md="3"
        class="text-center"
      >
        <v-btn x-large block color="primary" to="/cards" class="mb-2">
          Card Universe
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    document.title = "About Splinterlib";
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.top {
  .spacer {
    border-bottom: 1px solid $green;
    width: 100%;
    height: 50%;
  }
}
</style>
