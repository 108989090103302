import Splinterlib, { ALL } from "@knowdev/splinterlib";

//
//
// Constants
//

export const FILTER_MENU = {
  // todo: name
  edition: {
    name: "Edition",
    options: ALL.CARD.EDITIONS,
  },
  type: {
    name: "Type",
    options: ALL.CARD.TYPES,
  },
  rarity: {
    name: "Rarity",
    options: ALL.CARD.RARITIES,
  },
  splinter: {
    name: "Splinter",
    options: ALL.SPLINTERS,
  },
  // todo: mana
  ability: {
    name: "Ability",
    options: ALL.ABILITIES,
  },
  format: {
    name: "Formats",
    options: ALL.BATTLE.FORMATS,
  },
};

//
//
// State
//

const state = () => ({
  cards: Splinterlib.cardUniverse.all(),
  filter: {
    ability: [],
    edition: [],
    format: [],
    rarity: [],
    splinter: [],
    type: undefined,
  },
  useQa: false,
});

//
//
// Getters
//

export const GET = {
  CARD_COUNT: "cardUniverse/_cardCount",
};

const getters = {
  [GET.CARD_COUNT](state) {
    return state.cards.length;
  },
};

//
//
// Mutations
//

export const MUTATE = {
  RUN_FILTERS: "cardUniverse/_runFilters",
  SET_CARDS: "cardUniverse/_setCards",
  SET_FILTER: "cardUniverse/_setFilter",
  SET_USE_QA: "cardUniverse/_setUseQa",
};

const mutations = {
  [MUTATE.RUN_FILTERS](state) {
    const cardCollectionFilter = Splinterlib.cardCollectionFilter(state.filter);
    state.cards = Splinterlib.cardUniverse.all().filter(cardCollectionFilter);
  },
  [MUTATE.SET_CARDS](state, cards) {
    state.cards = cards;
  },
  [MUTATE.SET_FILTER](state, { filter, values }) {
    state.filter[filter] = values;
  },
  [MUTATE.SET_USE_QA](state, useQa) {
    state.useQa = useQa;
  },
};

//
//
// Actions
//

export const ACTION = {
  FILTER: "cardUniverse/_filter",
  REFRESH: "cardUniverse/_refresh",
  TOGGLE_QA: "cardUniverse/_toggleQa",
};

const actions = {
  async [ACTION.REFRESH]({ commit, state }, useQa) {
    const cardCollectionFilter = Splinterlib.cardCollectionFilter(state.filter);
    await Splinterlib.cardUniverse.refresh({ qa: useQa });
    const cards = Splinterlib.cardUniverse.all().filter(cardCollectionFilter);
    commit(MUTATE.SET_CARDS, cards);
  },
  async [ACTION.TOGGLE_QA]({ commit, dispatch }, useQa) {
    commit(MUTATE.SET_USE_QA, useQa);
    await dispatch(ACTION.REFRESH, useQa);
  },
  [ACTION.FILTER]({ commit }, { filter, values }) {
    commit(MUTATE.SET_FILTER, { filter, values });
    commit(MUTATE.RUN_FILTERS);
  },
};

//
//
// Export
//

export default {
  state,
  getters,
  mutations,
  actions,
};
