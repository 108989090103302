<template>
  <v-container fluid>
    <v-row dense>
      <v-col v-for="card in cards" :key="card.id" cols="12" sm="6" md="4">
        <SplinterlandsCard :card="card" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SplinterlandsCard from "./SplinterlandsCard";

export default {
  name: "CardCollection",

  components: {
    SplinterlandsCard,
  },

  props: {
    cards: Array,
  },
};
</script>

<style>

</style>
