<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {
    //
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import "@/styles/app.scss";
</style>
