<template>
  <v-card>
    <v-card-title>{{ card.name }} ({{ card.id }})</v-card-title>
    <v-card-text>
      <v-row class="mx-0">
        <v-chip small outlined class="mr-1 mb-1">{{ card.edition }}</v-chip>
        <v-chip small outlined class="mr-1 mb-1">{{ card.rarity }}</v-chip>
      </v-row>
      <v-row class="mx-0">
        <v-chip small outlined class="mr-1 mb-1">{{ card.splinter }}</v-chip>
        <v-chip small outlined class="mr-1 mb-1">{{ card.type }}</v-chip>
        <v-chip small outlined class="mr-1 mb-1">{{ mana }}</v-chip>
      </v-row>
      <v-row class="mx-0">
        <v-chip
          small
          outlined
          class="mr-1 mb-1"
          v-for="ability in card.abilities"
          :key="ability"
          >{{ ability }}</v-chip
        >
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SplinterlandsCard",
  props: {
    card: Object,
  },
  methods: {
    debugLog: function (card) {
      console.log(card);
    },
  },
  computed: {
    mana: function () {
      if (this.card.statRange.mana.high === this.card.statRange.mana.low) {
        return `${this.card.statRange.mana.high} Mana`;
      }
      return `${this.card.statRange.mana.low}-${this.card.statRange.mana.high} Mana`;
      // return this.card.name;
    },
  },
};
</script>

<style>

</style>
