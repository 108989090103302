<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mt-2 text-center">
        <h1>Splinterlib Demo</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="8"
        offset-sm="2"
        md="6"
        offset-md="3"
        class="text-center"
      >
        <v-btn x-large block color="primary" to="/cards" class="mb-2">
          Card Universe
        </v-btn>
        <v-btn medium block color="secondary" to="/about"> About </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" class="mt-6">
        <v-img
          alt="Splinterlib Crystal Ball"
          contain
          src="../assets/splinterlib.svg"
          width="100%"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    //
  }),

  methods: {
    //
  },

  mounted() {
    document.title = "Splinterlib Reference";
  },
};
</script>

<style>
</style>
