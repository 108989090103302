import Vue from "vue";
import Vuex from "vuex";

// import actions from "./actions";
// import mutations from "./mutations";
// import state from "./state";

import cardUniverse from "./modules/cardUniverse";

Vue.use(Vuex);

export default new Vuex.Store({
  // actions,
  // mutations,
  // state,
  modules: {
    cardUniverse,
  },
});
